import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Square from './components/square/Square';

import useLocalStorage from 'use-local-storage';
import { useState, useEffect } from 'react';
import { RecyclerListView, DataProvider } from "recyclerlistview";
import { useMediaQuery } from 'react-responsive'

import NavButton from './components/navigation/NavButton';

// import symbol image 
import symbolLight from './images/symbol_light_2.png'
import symbolDark  from './images/symbol_dark_2.png'

// import symbol svg
// import {ReactComponent as symbolLightSvg} from './images/symbol_light_3.svg'
// import {ReactComponent as symbolDarkSvg}  from './images/symbol_dark_3.svg'


function App() {
  // solution for darkMode 
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  // const defaultDark = false;
  var themeImage = defaultDark ? symbolLight : symbolDark;
  // var themeImageSvg = defaultDark ? symbolLightSvg : symbolDarkSvg;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const [darkMode, setDarkMode] = useState(defaultDark);

  // Responsive with hooks 
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  

  // // solution for darkMode 
  // const [darkMode, setDarkMode] = useState(false);

  // This applies style to the button so as to trigger the dark transition. 
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);
  

  // This is the main function for switching themes.
  const switchTheme = () => {
    const newTheme = darkMode ? 'dark' : 'light';
    setTheme(newTheme);
    setDarkMode(!darkMode);
  }

  // This controls which image to be shown on dark and light Mode.
  // var themeImage = darkMode ? symbolDark : symbolLight;
  var themeImage = darkMode ? symbolLight : symbolDark;
  // var themeImageSvg = defaultDark ? symbolLightSvg : symbolDarkSvg;




  return (
    <div className="App" data-theme={theme}>
      <button className="dark-mode-toggle" onClick={switchTheme}>
        {/* Switch to {theme === 'light'  ? 'dark' : 'light'} Theme */}
        <div className="dark-mode-slider" />
      </button>
      <div className='Teaser'>
        <div className="TeaserContent">
          <h1 className="TeaserTitle">InfiDyn</h1>
          {isDesktopOrLaptop && <div className="TeaserImageWrapper">
            <img src={themeImage} />
          </div>}
          {/* <div>
            symbolLightSvg is an actual React component
            <symbolLightSvg />
          </div> */}
          {/* <h2 className="TeaserSubtitle">From the Future</h2> */}
          {/* <h2 className="TeaserSubtitle">Transcending Future Being</h2> */}
          <h2 className="TeaserSubtitle">Transcending Being</h2>
        </div>
      </div>
      {/* <div className="NavWrapper">
        <NavButton navLabel="Blog"></NavButton>
        <NavButton navLabel="Projects"></NavButton>
        <NavButton navLabel="Add"></NavButton>
        <NavButton navLabel="Archive"></NavButton>
        <NavButton navLabel="Interact"></NavButton>
      </div>
      <div className="ContentWrapper">
        <div className="BlogNavigationWrapper">

        </div>
      </div> */}
    </div>
  );
}

export default App;
