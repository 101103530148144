import React from "react";
import './navbutton.css'


class NavButton extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clicked: null,
        };
    }

    render() {
        return(
            <div className="NavButtonBackground">
                <div className="NavButtonActual" onClick={() => {console.log('click')}}>
                </div>
                <p className="NavLabel">{this.props.navLabel}</p>
            </div>
        )
    }   
}

export default NavButton